<template>
    <Page
        :title="title"
        icon="mdi-printer"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        <v-card width="800" v-if="!isShowLoading">
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <select-field
                                :items="companies"
                                v-model="accountsCompanyId"
                                label="Company"
                            ></select-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <select-field
                                :items="[
                                    '12',
                                    '11',
                                    '10',
                                    '9',
                                    '8',
                                    '7',
                                    '6',
                                    '5',
                                    '4',
                                    '3',
                                    '2',
                                    '1',
                                ]"
                                v-model="month"
                                label="Month"
                            />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <select-field
                                :items="['2021', '2020', '2019']"
                                v-model="year"
                                label="Year"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="4"> </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <btn class="success" @click="runReport">Run Report</btn>
            </v-card-actions>
        </v-card>
    </Page>
</template>
<script>
import api from '@/api';

export default {
    data() {
        return {
            title: 'GL Trial Balance',
            loadingMessage: 'Loading',
            isShowLoading: true,
            errorMessage: '',
            isShowError: false,
            accountsCompanyId: null,
            year: '',
            month: '',
            companies: [],
        };
    },
    methods: {
        async runReport() {
            console.log('run', this.accountsCompanyId, this.year, this.month);
            const result = await this.$store.dispatch('runGLTrialBalance', {
                accountsCompanyId: this.accountsCompanyId,
                year: this.year,
                month: this.month,
            });
            console.log('RESULT', result);
        },
        async getCompanies() {
            const result = await api.get(this.$store, 'company');
            if (result.data && result.data.companies) {
                this.companies = result.data.companies.map(oneCompany => {
                    return {
                        text: oneCompany.companyName,
                        value: oneCompany.accountsCompanyId,
                    };
                });
            }
        },
    },
    async created() {
        this.accountsCompanyId = this.$store.getters.accountsCompanyId;
        await this.getCompanies();
        this.isShowLoading = false;
        this.loadingMessage = '';
    },
};
</script>
